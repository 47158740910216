<i18n>
{
  "en": {
    "title": "Custom Fields",
    "entity-1": "Cards",
    "entity-2": "Contacts",
    "entity-3": "Companies",
    "entity-4": "Users",
    "entity-5": "Products",
    "entity-6": "Bills",
    "create": "New field",
    "not-found": "Custom field not found",
    "not-found-description": "Make sure you have chosen the correct entity or create a new field",
    "options": "Options",
    "field-options": "Field options",
    "edit": "Edit field",
    "delete": "Delete field",
    "search": "Search for name of automation",
    "all": "All",
    "ungrouped": "Ungrouped",
    "groups": "Groups",
    "delete-cf-description": "By deleting this custom field all the information that was filled in it will be permanently deleted.",
    "duplicate": "Duplicate"
  },
  "pt-BR": {
    "title": "Campos Customizados",
    "entity-1": "Cards",
    "entity-2": "Contatos",
    "entity-3": "Empresas",
    "entity-4": "Usuários",
    "entity-5": "Produtos",
    "entity-6": "Financeiro",
    "create": "Novo campo",
    "not-found": "Nenhum campo encontrado",
    "not-found-description": "Verifique se selecionou a entidade correta ou crie um novo campo",
    "options": "Opções",
    "field-options": "Opções do campo",
    "edit": "Editar campo",
    "delete": "Deletar campo",
    "search": "Pesquisar pelo nome da automação",
    "all": "Tudo",
    "ungrouped": "Desagrupados",
    "groups": "Grupos",
    "delete-cf-description": "Ao deletar este campo customizado todas as informações que foram preenchidas nele serão permanentemente deletadas.",
    "duplicate": "Duplicar"
  }
}
</i18n>

<template>
  <div id="customfields-configs">
    <nav-top returnPage="configurations" :title="$t('title')">
      <template #top-content>
        <we-input type="search" v-model="search" :inputLabel="$t('search')" />
        <we-button
          class="success"
          :text="$t('create')"
          icon="plus"
          @click="$router.push({ name: 'createCustomField' })"
        />
        <confirm-modal
          :description="$t('delete-cf-description')"
          :open="confirmDeleteOpened"
          @close="confirmDeleteOpened = false"
          @confirm="deleteCustomField()"
        />
      </template>
      <template #menu>
        <we-nav-x
          v-if="navigationItems && navigationItems.length > 0"
          :items="navigationItems"
          :activeIndex="currentNavIndex"
          @select="changeEntity"
        />
      </template>
    </nav-top>
    <div class="board">
      <div class="customfields-grid">
        <div class="customfields-list">
          <router-view />
          <div
            class="item"
            v-for="group in generalGroups"
            :key="group"
            :class="{ active: group === selectedGroup }"
            @click="selectedGroup = group"
          >
            {{ getGroup(group) }}
          </div>
          <div
            class="list-name"
            v-if="customFieldGroups && customFieldGroups.length > 0"
          >
            {{ $t("groups") }}
          </div>
          <div
            class="item"
            v-for="group in customFieldGroups"
            :key="group"
            :class="{ active: group === selectedGroup }"
            @click="selectedGroup = group"
          >
            {{ group }}
          </div>
        </div>
        <div class="customfield-selected">
          <div class="customfield-list">
            <we-not-found v-if="searchedCustomFields.length === 0">
              <template #title> {{ $t("not-found") }} </template>
              <template #description>
                {{ $t("not-found-description") }}
              </template>
              <template #actions>
                <we-button
                  class="success"
                  :text="$t('create')"
                  icon="plus"
                  @click="$router.push({ name: 'createCustomField' })"
                />
              </template>
            </we-not-found>
            <container
              class="list-group"
              :animation-duration="200"
              @drop="e => moveCustomField(e)"
              :get-child-payload="getCardPayload()"
              drag-class="dragged"
              :scrollSensitivity="200"
            >
              <draggable v-for="field in searchedCustomFields" :key="field.id">
                <v-card
                  class="item"
                  @contextmenu="showOptions({ event: $event, field })"
                >
                  <div class="name">
                    {{ field.label }}
                    <span class="type"> • {{ field.type }}</span>
                    <span v-if="field.mask" class="mask">
                      ({{ field.mask }})
                    </span>
                    <span
                      v-if="
                        field.values &&
                          field.values.length > 0 &&
                          !['link', 'div'].includes(field.type)
                      "
                    >
                      ({{ field.values.length }} {{ $t("options") }})</span
                    >
                  </div>
                  <div class="actions">
                    <we-balloon>
                      <template #action>
                        <we-icon-button
                          icon="ellipsis-h"
                          :name="$t('field-options')"
                        />
                      </template>
                      <template #balloon>
                        <div
                          class="balloon-item"
                          @click="
                            $router.push({
                              name: 'editCustomField',
                              params: { id: field.id }
                            })
                          "
                        >
                          <font-awesome-icon icon="edit" />
                          {{ $t("edit") }}
                        </div>
                        <div
                          class="balloon-item"
                          @click="
                            selectedCustomField = field;
                            duplicateCustomField();
                          "
                        >
                          <font-awesome-icon :icon="['far', 'copy']" />
                          {{ $t("duplicate") }}
                        </div>
                        <div
                          class="balloon-item"
                          @click="
                            confirmDeleteOpened = true;
                            selectedCustomFieldId = field.id;
                          "
                        >
                          <font-awesome-icon
                            :icon="{ prefix: 'far', iconName: 'trash-alt' }"
                          />
                          {{ $t("delete") }}
                        </div>
                      </template>
                    </we-balloon>
                  </div>
                </v-card>
              </draggable>
            </container>
            <we-nested-menu
              name="Custom Field Menu"
              v-model="showMenu"
              :position-x="x"
              :position-y="y"
              :absolute="true"
              :offset-x="true"
              :menu-items="fieldActionsMenu"
              @we-nested-menu-click="onMenuItemClick"
            />
          </div>
        </div>
      </div>
      <we-loading-overflow :loading="loading" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Container, Draggable } from "vue-smooth-dnd";
export default {
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      confirmDeleteOpened: false,
      selectedGroup: "all",
      search: "",
      currentNavIndex: 0,
      loading: true,
      selectedCustomFieldId: 0,
      showMenu: false,
      selectedCustomField: {},
      x: 0,
      y: 0
    };
  },
  computed: {
    generalGroups() {
      return ["all", "ungrouped"].filter(e => {
        if (this.customFieldGroups.length <= 0 && e === "ungrouped")
          return false;

        return true;
      });
    },
    fieldActionsMenu() {
      return [
        {
          name: this.$t("duplicate"),
          icon: ["far", "copy"],
          action: () => {
            this.duplicateCustomField();
          }
        }
      ];
    },
    entity() {
      return this.$store.getters.getCurrentCustomFieldsEntity;
    },
    customFieldGroups() {
      return [...this.$store?.getters?.getCustomFieldGroups];
    },
    navigationItems() {
      return [
        {
          id: 0,
          name: this.$t("entity-1"),
          icon: "note-sticky",
          entity: "deal"
        },
        {
          id: 2,
          name: this.$t("entity-2"),
          icon: "users",
          entity: "contact"
        },
        {
          id: 3,
          name: this.$t("entity-3"),
          icon: "building",
          entity: "company"
        },
        {
          id: 4,
          name: this.$t("entity-4"),
          icon: "user-tie",
          entity: "user"
        },
        {
          id: 5,
          name: this.$t("entity-5"),
          icon: "cubes",
          entity: "product"
        },
        {
          id: 6,
          name: this.$t("entity-6"),
          icon: "file-invoice-dollar",
          entity: "bill"
        }
      ];
    },
    customFields() {
      return this.$store.getters.getCustomFields;
    },
    customFieldEntityList() {
      return this.$store.getters.getCustomFieldEntityList;
    },
    filteredCustomFields() {
      return this.customFields.filter(e => {
        if (e.entity != this.entity) return false;

        if (this.selectedGroup === "all") return true;

        if (this.selectedGroup === "ungrouped") {
          if (!e.group) {
            return true;
          }
        }

        if (this.selectedGroup === e.group) {
          return true;
        }

        return false;
      });
    },
    searchedCustomFields() {
      return this.filteredCustomFields.filter(e => {
        if (this.search.length <= 0) return true;

        if (e.label.toLowerCase().includes(this.search.toLowerCase())) {
          return true;
        }

        return false;
      });
    }
  },
  methods: {
    ...mapActions([
      "customFieldsRequest",
      "deleteCustomFieldRequest",
      "editCustomFieldRequest",
      "customFieldGroupsRequest",
      "createCustomFieldRequest"
    ]),
    getGroup(group) {
      if (group === "all") return this.$t("all");

      if (group === "ungrouped") return this.$t("ungrouped");

      return "";
    },
    onMenuItemClick(item) {
      if (item.action) {
        item.action();
      }
    },
    async duplicateCustomField() {
      this.loading = true;
      await this.createCustomFieldRequest(this.selectedCustomField);
      this.doRequests();
    },
    showOptions({ event, field }) {
      this.selectedCustomField = { ...field };

      event.preventDefault();
      this.showMenu = false;
      this.x = event.clientX;
      this.y = event.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    getCardPayload() {
      return index => {
        return this.searchedCustomFields[index];
      };
    },
    async doRequests() {
      this.loading = true;
      await this.customFieldsRequest({
        entity: this.entity
      });
      await this.customFieldGroupsRequest({
        params: {
          entity: this.entity
        }
      });
      this.loading = false;
    },
    async deleteCustomField() {
      this.loading = true;
      await this.deleteCustomFieldRequest(this.selectedCustomFieldId);
      await this.doRequests();
    },
    changeEntity(event) {
      this.currentNavIndex = event;
      this.selectedGroup = "all";
      this.search = "";
      this.$store.commit(
        "setCurrentCustomFieldsEntity",
        this.navigationItems[event].entity
      );
      this.doRequests();
    },
    async moveCustomField(evt) {
      let customField = evt.payload;
      this.searchedCustomFields.splice(evt.removedIndex, 1);
      this.searchedCustomFields.splice(evt.addedIndex, 0, evt.payload);
      customField.indexes = this.searchedCustomFields.map(e => e.id);

      await this.editCustomFieldRequest(customField);
      this.doRequests();
    },
    setCustomFieldEntity() {
      const entity = this.$store.getters.getCurrentCustomFieldsEntity;
      const index = this.navigationItems.findIndex(e => e.entity === entity);
      this.currentNavIndex = index;
    }
  },
  mounted() {
    this.doRequests();
    this.setCustomFieldEntity();
  },
  beforeRouteUpdate(to, from, next) {
    if (from.name.includes("create") || from.name.includes("edit")) {
      this.doRequests();
    }
    next();
  }
};
</script>

<style lang="scss">
#customfields-configs {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  position: relative;

  .board {
    background: var(--background-2);
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    flex: 1;
    min-height: 0;
  }

  .customfields-grid {
    min-height: 500px;
    max-width: 800px;
    height: fit-content;
    margin: 0 auto;
    padding: 69px 50px;
    display: grid;
    grid-template-columns: 227px $column-size;
    gap: 18px;

    .customfields-list {
      border-right: 1px solid var(--line);
      padding-right: 18px;

      .list-name {
        font-size: 14px;
        font-weight: $medium;
        text-transform: uppercase;
        color: var(--text-3);
        padding-left: 18px;
        padding-top: 16px;
        margin-top: 22px;
        border-top: 1px solid var(--line);
        margin-bottom: 8px;
        letter-spacing: 1px;
      }

      .item {
        height: fit-content;
        display: flex;
        align-items: center;
        padding: 9.5px 18px;
        font-size: 14px;
        font-weight: $medium;
        color: var(--text-1);
        background: var(--action);
        border-radius: 7px;
        cursor: pointer;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 60px;
        }

        &:hover {
          background: var(--action-hover);
        }

        &.active {
          color: var(--primary);
          background: var(--primary-bg);
        }
      }
    }

    .customfield-selected {
      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;

        .name {
          font-size: 24px;
          font-weight: $regular;
          color: var(--text-1);
        }

        .actions {
          display: flex;
          align-items: center;

          button {
            margin-left: 4px;
          }
        }
      }

      .customfield-list {
        position: relative;
        min-height: 320px;
        position: relative;

        .item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 4px 12px;
          padding-right: 4px;
          margin-bottom: 12px;
          border-radius: 10px;
          background: var(--background-1);
          border: 1px solid var(--line);
          cursor: grab;

          &:hover {
            border: 1px solid var(--primary);
          }

          &.disabled {
            background: var(--background-1-hover);
            cursor: not-allowed;
            border: 1px solid var(--line);
            min-height: 50px;
          }

          span {
            text-transform: capitalize;
          }

          .name {
            font-size: 16px;
            font-weight: $medium;
            color: var(--text-1);
            padding: 4px 0;

            span {
              font-size: 14px;
              color: var(--text-2);
            }
          }
        }
      }
    }
  }
}
</style>
