<i18n>
{
  "en": {
    "title-create": "New Custom Field",
    "title-update": "Update Custom Field",
    "input-1": "Name",
    "input-2": "Description",
    "input-3": "Layout",
    "input-4": "Entity",
    "input-5": "Type",
    "input-6": "Values",
    "input-7": "Mask",
    "input-8": "Url",
    "input-9": "Group",
    "preview": "Preview",
    "switch-1": "Required",
    "switch-2": "Read only",
    "switch-3": "Dependent field",
    "dynamic-1": "If field",
    "dynamic-2": "has value",
    "remove": "Remove condition",
    "add": "Add condition",
    "create": "Create field",
    "update": "Save updates",
    "label-rule": "Special chars not allowed"
  },
  "pt-BR": {
    "title-create": "Novo Campo",
    "title-update": "Editar Campo",
    "input-1": "Nome",
    "input-2": "Descrição do campo",
    "input-3": "Layout",
    "input-4": "Entidade",
    "input-5": "Tipo",
    "input-6": "Valores",
    "input-7": "Máscara",
    "input-8": "Url",
    "input-9": "Grupo",
    "preview": "Pré-visualização",
    "switch-1": "Obrigatório",
    "switch-2": "Somente leitura",
    "switch-3": "Campo dependente",
    "dynamic-1": "Se o campo",
    "dynamic-2": "Tiver o valor",
    "remove": "Remover condição",
    "add": "Adicionar condição",
    "create": "Criar campo",
    "update": "Salvar alterações",
    "label-rule": "Remova os caracteres especiais"
  }
}
</i18n>

<template>
  <modal-action @close="closeCustomField">
    <template #view>
      <div class="modal">
        <div class="modal-header">
          <div class="name">
            <div class="icon">
              <font-awesome-icon :icon="customFieldExist ? 'edit' : 'plus'" />
            </div>
            {{ customFieldExist ? $t("title-update") : $t("title-create") }}
          </div>
          <div class="options">
            <we-icon-button icon="times" @click="closeCustomField" />
          </div>
        </div>
        <div class="modal-body">
          <v-form ref="form">
            <div class="fields">
              <we-input
                type="text"
                :label="$t('input-1')"
                v-model="customField.label"
                @input="inputLabel"
                :rules="labelRules"
                is-required
              />
              <we-input
                type="tag"
                v-model="customField.group"
                :items="groupsList"
                :clearable="false"
                :label="$t('input-9')"
              />
              <we-input
                type="text"
                :label="$t('input-2')"
                v-model="customField.placeholder"
              />
              <we-input
                type="global-select"
                :label="$t('input-3')"
                :items="customFieldLayoutList"
                v-model="customField.layout"
                is-required
              />
              <we-input
                type="global-select"
                :label="$t('input-4')"
                :clearable="false"
                :items="entityList"
                v-model="customField.entity"
                is-required
              />
              <we-input
                type="global-select"
                :label="$t('input-5')"
                :clearable="false"
                :items="typeList"
                v-model="customField.type"
                @input="changeType"
                is-required
              />
              <we-input
                v-if="
                  ['select', 'multiselect', 'select-search'].includes(
                    customField.type
                  )
                "
                type="tags"
                :label="$t('input-6')"
                :clearable="false"
                :items="customField.values"
                v-model="customField.values"
              />
              <we-code-editor
                v-if="['global-select'].includes(customField.type)"
                :label="$t('input-6')"
                class="double-column"
                v-model="globalSelectInput"
                @input="globalSelectUpdate"
              />
              <we-input
                v-if="customField.type === 'text'"
                type="text"
                :label="$t('input-7')"
                v-model="customField.mask"
              />
              <we-input
                v-if="customField.type === 'link'"
                type="text"
                :label="$t('input-8')"
                v-model="customField.values[0]"
              />
            </div>
          </v-form>
        </div>
        <div class="modal-body preview">
          <div class="we-label">
            {{ $t("preview") }}
          </div>
          <div class="fields">
            <we-input
              :class="customField.layout"
              :type="customField.type || 'text'"
              :label="customField.label"
              :items="customField.values"
              :isRequired="customField.is_required"
              :readonly="customField.is_read_only"
              :placeholder="customField.placeholder"
              v-model="previewValue"
              :mask="customField.mask"
            />
          </div>
        </div>
        <div class="modal-body" v-if="!isSeparator">
          <div class="checkbox">
            <v-switch
              v-model="customField.is_required"
              @change="changeRequired"
              :label="$t('switch-1')"
              hide-details
            />
            <v-switch
              v-model="customField.is_read_only"
              @change="changeReadOnly"
              :label="$t('switch-2')"
              hide-details
            />
            <v-switch
              v-model="is_dependent"
              @change="changeDependent"
              :label="$t('switch-3')"
              hide-details
            />
          </div>
          <div class="fields" v-if="is_dependent">
            <div
              class="dynamic-fields only-two double-column"
              v-for="(condition, i) in customFieldConditions"
              :key="condition.id"
            >
              <we-input
                type="global-select"
                v-model="condition.filterField"
                :label="$t('dynamic-1')"
                @input="changeConditionField(condition)"
                :items="fieldsList"
                :clearable="false"
              />
              <we-input
                v-show="verifyOperator(condition.operator)"
                :type="condition.valueField.type || 'text'"
                v-model="condition.valueField.value"
                :items="condition.valueField.items"
                :clearable="false"
                :label="$t('dynamic-2')"
              />
              <we-icon-button
                :icon="{ prefix: 'far', iconName: 'trash-alt' }"
                :name="$t('remove')"
                @click="removeCustomFieldCondition(i)"
              />
            </div>
            <div class="double-column">
              <we-button
                class="disabled centered email"
                :text="$t('add')"
                icon="plus"
                @click="addCondition"
              />
            </div>
          </div>
        </div>
        <div class="bottom">
          <we-button
            class="green"
            :text="customFieldExist ? $t('update') : $t('create')"
            :icon="customFieldExist ? 'check' : 'plus'"
            :loading="btnLoading"
            @click="customFieldAction"
          />
        </div>
        <we-loading-overflow :loading="loading" />
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      previewValue: "",
      loading: true,
      btnLoading: false,
      is_dependent: false,
      globalSelectInput: "",
      customField: {
        custom_fields_group_id: 1,
        entity: "contact",
        name: "",
        label: "",
        layout: "single-column",
        placeholder: "",
        type: "text",
        mask: "",
        values: [],
        is_required: false,
        is_read_only: false,
        is_available_externally: false,
        index: 1,
        visibility_condition: []
      }
    };
  },
  computed: {
    labelRules() {
      return [
        v =>
          !/[`!@#$%^&*_+\-=[\]{};':"\\|,.<>/?~]/.test(v) ||
          this.$t("label-rule")
      ];
    },
    isSeparator() {
      return ["div"].includes(this.customField.type);
    },
    groups() {
      return this.$store?.getters?.getCustomFieldGroups;
    },
    groupsList() {
      if (!this.customField.group) return this.groups;

      return [this.customField.group, ...this.groups];
    },
    customFieldExist() {
      return this.$route.params.id;
    },
    entityList() {
      return this.$store.getters.getCustomFieldEntityList;
    },
    typeList() {
      return this.$store.getters.getCustomFieldTypeList;
    },
    customFieldsList() {
      return (
        this.customFields?.map(e => {
          return {
            text: e.label,
            value: e.name
          };
        }) || []
      );
    },
    customFieldLayoutList() {
      return this.$store.getters.getCustomFieldLayoutList;
    },
    fieldsList() {
      return [...this.customFieldsList];
    },
    customFieldConditions() {
      return this.$store.getters.getCustomFieldConditions;
    },
    customFields() {
      return this.$store.getters.getDealCustomFieldsInputs;
    },
    indexList() {
      let list = [];
      for (let i = 1; i <= this.customFields.length + 1; i++) {
        list.push(i);
      }
      return list;
    }
  },
  methods: {
    ...mapActions([
      "editCustomFieldRequest",
      "createCustomFieldRequest",
      "customFieldRequest",
      "customFieldsRequest",
      "customFieldGroupsRequest"
    ]),
    globalSelectUpdate(event) {
      this.globalSelectInput = event;

      if (this.$options.filters.isJsonString(event)) {
        const values = JSON.parse(event);

        if (Array.isArray(values)) {
          this.customField.values = values;
        }
      }
    },
    inputLabel() {
      if (this.isSeparator) {
        this.customField.placeholder = this.customField.label;
      }
    },
    customFieldAction() {
      const validated = this.$refs.form.validate();

      if (validated) {
        this.customFieldExist
          ? this.editCustomField()
          : this.createCustomField();
      }
    },
    verifyOperator(operator) {
      return !["false", "true", "null"].includes(operator);
    },
    addCondition() {
      const fieldObject = {
        id: this.customFieldConditions.length + 1,
        filterField: "",
        operator: "=",
        valueField: {
          value: "",
          type: "text",
          mask: "",
          items: []
        }
      };

      this.$store.commit("addCustomFieldCondition", fieldObject);
    },
    removeCustomFieldCondition(index) {
      this.$store.commit("removeCustomFieldCondition", index);
    },
    changeConditionField(field) {
      field.valueField.value = "";
      field.valueField.mask = "";
      field.valueField.type = "text";
    },
    changeDependent(val) {
      if (!val) {
        this.$store.commit("resetCustomFieldConditions");
      }
    },
    changeReadOnly(val) {
      if (val) {
        this.customField.is_required = false;
      }
    },
    changeRequired(val) {
      if (val) {
        this.customField.is_read_only = false;
      }
    },
    closeCustomField() {
      this.$router.push({
        name: "customFieldsConfigs"
      });
    },
    changeType() {
      this.previewValue = "";
      this.customField.mask = "";
      this.customField.values = [];

      if (this.customField.type === "global-select") {
        const exampleValues = `[
  {
    "text": "Text",
    "value": "Value"
  }
]`;

        this.globalSelectInput = exampleValues;
        this.customField.values = JSON.parse(exampleValues);
      }
    },
    async createCustomField() {
      this.btnLoading = true;
      this.customField.visibility_condition = this.customFieldConditions
        .filter(e => e.filterField.trim().length > 0)
        .map(field => {
          return {
            field_name: field.filterField,
            condition: "=",
            value: field.valueField.value
          };
        });

      await this.createCustomFieldRequest(this.customField);
      this.closeCustomField();
      this.btnLoading = false;
    },
    async editCustomField() {
      this.btnLoading = true;
      this.customField.visibility_condition = this.customFieldConditions
        .filter(e => e.filterField.trim().length > 0)
        .map(field => {
          return {
            field_name: field.filterField,
            condition: "=",
            value: field.valueField.value
          };
        });

      await this.editCustomFieldRequest(this.customField);
      this.closeCustomField();
      this.btnLoading = false;
    },
    async doRequests() {
      await this.customFieldsRequest();

      if (this.customFieldExist) {
        await this.customFieldRequest(this.$route.params.id);

        this.customField = this.$store.getters.getCustomField;

        if (this.customField.type === "global-select") {
          this.globalSelectInput = JSON.stringify(
            this.customField.values,
            null,
            2
          );
        }

        if (this.customField.visibility_condition?.length > 0) {
          this.is_dependent = true;

          let items = [];
          this.customField.visibility_condition.forEach((element, i) => {
            let field = {
              id: i,
              filterField: element.field_name,
              operator: element.condition,
              valueField: {
                value: element.value,
                type: "text",
                mask: "",
                items: []
              }
            };

            if (field.valueField.value.toString().includes("cf_")) {
              const customFieldName = field.valueField.value.split("%")[1];
              const customFieldValue = field.valueField.value.split("%")[2];

              field.filterField = customFieldName;
              field.valueField.value = customFieldValue;
            }

            items.push(field);
          });

          this.$store.commit("setCustomFieldConditions", items);
        }
      } else {
        this.customField.entity =
          this.$store.getters.getCurrentCustomFieldsEntity || "contact";

        if (this.$route.query.entity) {
          this.customField.entity = this.$route.query.entity;
        }
      }

      this.loading = false;
    }
  },
  async mounted() {
    this.$store.commit("resetCustomFieldConditions");
    if (this.groups.length <= 0) {
      await this.customFieldGroupsRequest();
    }
    await this.doRequests();
  }
};
</script>
